import React from 'react';
import axios from 'axios';
import countries from '../assets/countries.json';
import cities from '../assets/cities.json';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Dialog from '@mui/material/Dialog';

import Slide from '@mui/material/Slide';

import Fade from 'react-reveal/Fade';

import Lottie from 'react-lottie';
import animationData from '../lottie/done.json';


/* global google */


class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.state = {
      fieldValue: '',
    }
  }

  componentDidMount() {

    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
      { "types": ["locality", "country"], "fields":["formatted_address"] });

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    try{
      var title = place.formatted_address;
      if(title !== undefined){
        console.log(place);
        this.props.onPlaceLoaded(place);
        this.setState({ fieldValue: '' });
        return;
      }
    }catch(e){

    }
    this.setState({ fieldValue: '' });
    //this.props.onEnter(this.state.fieldValue);
   
    return;
  }
  scrollTo(ref) {
    // window.scrollTo(0, ref.current.offsetTop);
    try {
      window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop });
    } catch (e) {

    }
  }
  change(e) {
    this.setState({ fieldValue: e.target.value });
  }
  render() {
    return (
      <input
        ref={this.autocompleteInput}
        value={this.state.fieldValue}
        onChange={(e) => this.change(e)}
        id="autocomplete"
        className='text-input'
        placeholder={this.props.placeholder}
        onClick={() => {
          document.documentElement.style.setProperty('--silvy-google-hover', this.props.hoverColor);
          document.documentElement.style.setProperty('--silvy-google-text', this.props.textColor);
          this.scrollTo(this.autocompleteInput)
        }}
        type="text">

      </input>
    );
  }
}

class JoinRequest extends React.Component {

  // ref = useRef();

  constructor(props) {
    super(props)
    this.ref = React.createRef();
    this.ref2 = React.createRef();
    this.refFullName = React.createRef();
    this.refNextDest = React.createRef();
    this.refLastDest = React.createRef();
    this.refButton = React.createRef();
    this.refEmail = React.createRef();
    this.state = {

      fullName: '',
      /// next
      nextDest: [],
      nextDestVal: '',
      nextDestSug: [],
      nextDestSugOpen: false,
      /// last

      lastDest: [],
      lastDestVal: '',
      lastDestSug: [],
      lastDestSugOpen: false,

      email: '',
      errMsgFullName: null,
      errMsgEmail: null,

      openSuccess: false,


    }
  }


  defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }

  };

  findCountry(countryName) {
    for (var a in countries) {
      var country = countries[a]['name'];
      if (country.toLowerCase().startsWith(countryName.toLowerCase())
        ||
        country.toLocaleLowerCase().includes(countryName.toLowerCase())) {
        return countries[a]['emoji'] + " " + countries[a]['name'];
      }
    }
    return "";
  }





  changeHandler = (e) => {
    var isLast = e.target.name === "lastDest";
    var isNext = e.target.name === "nextDest"
    var inputValue = e.target.value;
    if (isLast || isNext) {
      var list = [];
      list.push(inputValue);
      for (var a in cities) {
        var city = cities[a]['city'];
        var countryName = this.findCountry(cities[a]['country']);

        if (inputValue.length > 0 && city.toLowerCase().includes(inputValue.toLowerCase())
          ||
          countryName.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          var country = cities[a]['city'];
          if (countryName.length > 0) {
            country = cities[a]['city'] + "(" + countryName + ")";
          }
          list.push(country);
        }
      }

      for (var a in countries) {
        var country = countries[a]['name'];
        if (inputValue.length > 0 && country.toLowerCase().includes(inputValue.toLowerCase())
          ||
          country.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          var countryFound = countries[a]['emoji'] + " " + countries[a]['name'];
          list.push(countryFound);
        }
      }
      //console.log(list);
      list = list.slice(0, 6);
      if (isNext) {
        this.setOpen(true);
        this.setState({ ['nextDestSug']: list, ['nextDestVal']: inputValue });
      }
      if (isLast) {
        this.setOpenLast(true);
        this.setState({ ['lastDestSug']: list, ['lastDestVal']: inputValue });
      }
    } else {
      if (e.target.name === 'fullName') {
        // this.validateFullName();
      } else if (e.target.name === 'email') {
        // this.validateEmail();
      }
      this.setState({ [e.target.name]: inputValue });

    }
  };


  validateFullName() {
    var fullNameValid = false;

    if (this.state.fullName == '') {
      this.setState({ ['errMsgFullName']: 'Please enter your full name' });
    } else {
      this.setState({ ['errMsgFullName']: null });
      this.state.errMsgFullName = null;
      fullNameValid = true;
    }
    return fullNameValid
  }

  validateEmail() {
    var emailValid = false;
    if (this.state.email == '') {
      this.setState({ ['errMsgEmail']: 'Please enter your e-mail' });
    } else {
      var email = this.state.email;
      emailValid = /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/.test(email);
      if (!emailValid) {
        this.setState({ ['errMsgEmail']: 'Please enter valid e-mail' });
      } else {
        this.setState({ ['errMsgEmail']: null });
      }
    }
    return emailValid;
  }

  validateForm() {
    /// Full Name Validator
    var fullNameValid = this.validateFullName();
    var emailValid = this.validateEmail();

    return emailValid && fullNameValid;
  }


  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  async handleClickOpen() {
    this.setState({ ['openSuccess']: true })
  };

  async handleClose() {
    this.setState({ ['openSuccess']: false })

  };


  async submitFunction(e) {

    var firstName = '';
    var lastName = '';
    try {
      var fullName = this.state.fullName.split(' '),
        firstName = fullName[0],
        lastName = fullName.slice(1).join(' ');;
    } catch (err) { }

    if (this.validateForm()) {
      this.send(firstName, lastName, this.state.fullName, this.state.nextDest, this.state.lastDest, this.state.email);
    } else {
      /// todo error handling
      ///console.log('Validation failed');
    }

  }

  clearFields() {
    this.state = {
      fullName: '',
      nextDest: [],
      nextDestSug: [],
      lastDest: [],
      lastDestSug: [],
      email: '',
      errMsgFullName: null,
      errMsgEmail: null,
    }
    this.setState(this.state);
  }

  async getList() {
    // var q = 'sp'
    // var API_KEY = 'AIzaSyDqGKBCiQDpPOuWrkb-TtseT1BSJYeU3DY';
    // var url = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input="+q+"&types=locality|country&key=" + API_KEY;
    //   axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
    //   axios.get(url)
    //     .then((result) => {
    //       // Do somthing
    //       console.log(result);
    //       console.log(result.data);
    //     })
    //     .catch((err) => {
    //       // Do somthing
    //     })
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
      { "types": ["geocode"] });

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  async send(firstName, lastName, fullName, nextDest, lastDest, email) {

    var url = "https://78879adf.sibforms.com/serve/MUIEADY-dMA2PCvW9TILcm75O46-SJN_1WxIUlBM-Wx8S-XyrGcPXwvaqORfug62VSp9OY7dPn7TV8vZ4hG95aHRyhrblkfGcsW4b2uMQYmVngMcDGM_uPtiJb6Y9MtvI6tje0zGeREOQmUjldk2qbuWCQ55iTrjaqqUSuIxqr6VEUc4ZMfv9O8VMvcgN-se5b09iRlZ7s-m9_M4?isAjax=1";

    const params = new URLSearchParams()
    params.append('FULLNAME', fullName);
    params.append('LASTNAME', lastName);
    params.append('FIRSTNAME', firstName);
    params.append('NEXT_DEST', nextDest);
    params.append('PREV_DEST', lastDest);
    params.append('EMAIL', email);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    axios.post(url, params, config)
      .then((result) => {
        // Do somthing
        this.clearFields();
        this.handleClickOpen();
      })
      .catch((err) => {
        // Do somthing
      })

  }

  removeEmoji(text) {
    return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '').trim();
  }
  onPlaceLoaded(place) {
    try{
    this.addNext(place.formatted_address);
    }catch(e){

    }
  }

  onEnter(place) {
    try{
    this.addNext(place);
    }catch(e){

    }
  }
  onEnterLast(place) {
    try{
    this.addLast(place);
    }catch(e){

    }
  }
  onPlaceLoadedLast(place) {
    try{
    this.addLast(place.formatted_address);
    }catch(e){

    }
  }
  addNext(dest) {
    /// - Skip if: Already exist in our array
    const exists = this.state.nextDest.some(v => {
      /// Remove emoji flag
      v = this.removeEmoji(v);
      var tmpDest = this.removeEmoji(dest);
      // Remove spaces
      v = v.trim();
      /// Compare
      return (v.toLowerCase() === (tmpDest.toLowerCase()));
    });
    if (exists) return;


    var a = [...this.state.nextDest, dest];
    this.setState({
      ['nextDest']: a,
      ['nextDestVal']: '',
      ['nextDestSug']: [],
    });
  }

  removeNext(dest) {
    var array = [...this.state.nextDest];
    var index = array.indexOf(dest)
    if (index !== -1) {
      array.splice(index, 1);
    }
    this.setState({
      ['nextDest']: array,
      ['nextDestVal']: '',
      ['nextDestSug']: [],
    });
  }


  nextField(e) {

    /// Enter pressed
    if (e.key === 'Enter') {
      var field = e.target.name;
      if (field === "fullName") {
        var valid = this.validateFullName();
        if (valid) {
          this.refNextDest.current.focus();
        }

      } else if (field === "nextDest") {
        this.refLastDest.current.focus();
      } else if (field === "lastDest") {
        this.refEmail.current.focus();
      } else if (field === "email") {
        //this.refFullName.current.focus();        
        this.refButton.current.click();
      }
    }
  }
  onKeyUp(e, tag) {
    if (e.key === 'Escape') {
      this.setState({ ['nextDestSug']: [] });
      return;
    } else if (e.key === 'Backspace') {
      if (this.state.nextDest.length > 0 && this.state.nextDestVal.length == 0) {
        this.removeNext(this.state.nextDest.at(-1))
      }
      return;
    }
  }

  ////
  addLast(dest) {
    /// - Skip if: Already exist in our array
    const exists = this.state.lastDest.some(v => {
      /// Remove emoji flag
      v = this.removeEmoji(v);
      var tmpDest = this.removeEmoji(dest);
      // Remove spaces
      v = v.trim();
      /// Compare
      return (v.toLowerCase() === (tmpDest.toLowerCase()));
    });
    if (exists) return;


    var a = [...this.state.lastDest, dest];
    this.setState({
      ['lastDest']: a,
      ['lastDestVal']: '',
      ['lastDestSug']: [],
    });
  }

  removeLast(dest) {
    var array = [...this.state.lastDest];
    var index = array.indexOf(dest)
    if (index !== -1) {
      array.splice(index, 1);
    }
    this.setState({
      ['lastDest']: array,
      ['lastDestVal']: '',
      ['lastDestSug']: [],
    });
  }

  onKeyUpLast(e, tag) {
    if (e.key === 'Escape') {
      this.setState({ ['lastDestSug']: [] });
      return;
    } else if (e.key === 'Backspace') {
      if (this.state.lastDest.length > 0 && this.state.lastDestVal.length == 0) {
        this.removeLast(this.state.lastDest.at(-1))
      }
      return;
    }
  }

  ////

  setOpen(o) {
    this.setState({ ['nextDestSugOpen']: o });
  }
  setOpenLast(o) {
    this.setState({ ['lastDestSugOpen']: o });
  }

  scrollTo(ref) {
    // window.scrollTo(0, ref.current.offsetTop);
    try {
      window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop });
    } catch (e) {

    }
  }

  onAnimationFinish() {
    // alert(1);
  }
 

  render() {
    const { fullName, nextDest, nextDestSug,
      lastDestSug, lastDest, email, nextDestVal, lastDestVal, nextDestSugOpen, lastDestSugOpen,
      openSuccess
      , errMsgEmail, errMsgFullName } = this.state;

    return (

      <div className='formFields2'  >

        <input onClick={() => this.scrollTo(this.refFullName)}
          onKeyUp={(e) => this.nextField(e)} name="fullName" ref={this.refFullName} type="text" value={fullName} onChange={this.changeHandler} className="App-input" placeholder="Full Name" required />
        {errMsgFullName && <Fade up> <p className='App-err-field'>{errMsgFullName}</p> </Fade>}


        <div className='parent'  >
          {nextDest.length > 0 && nextDest.map((dest) => {
            return <div className='child-tag-item-next'>
              <span className='child-tag-item-text' onClick={() => this.removeNext(dest)} >{dest}</span>
              <span onClick={() => this.removeNext(dest)} className='child-tag-item-close'>&times;</span>
            </div>;
          })}
         <SearchBar ref={this.refNextDest}
                onClick={() => this.scrollTo(this.refNextDest)}
                onPlaceLoaded={(place)=>this.onPlaceLoaded(place)}
                onEnter={(item)=>this.onEnter(item)}
                placeholder="Next Destination(s)"
                hoverColor="#ffc946"
                textColor="black"
                ></SearchBar>
        </div>
        <div ref={this.ref2} className='parent'>

          {lastDest.length > 0 && lastDest.map((dest) => {
            return <div className='child-tag-item-last'>
              <span className='child-tag-item-text' onClick={() => this.removeLast(dest)} >{dest}</span>
              <span onClick={() => this.removeLast(dest)} className='child-tag-item-close'>&times;</span>
            </div>;
          })}
       <SearchBar ref={this.refLastDest}
                onClick={() => this.scrollTo(this.refLastDest)}
                onPlaceLoaded={(place)=>this.onPlaceLoadedLast(place)}
                onEnter={(item)=>this.onEnterLast(item)}
                placeholder="Last Destination(s)"
                hoverColor="#7378ff"
                textColor="black"
                ></SearchBar>
      

        </div>

        <div className="App-row">
          <div className='App-row-email'>
            <input onKeyUp={(e) => this.nextField(e)}
              ref={this.refEmail}
              name="email"
              type="text" value={email}
              type="email"
              onClick={() => this.scrollTo(this.refEmail)}
              onChange={this.changeHandler} className="App-input App-input-row" placeholder="E-mail Address" required />
            {errMsgEmail && <Fade up><p className='App-err-field'>{errMsgEmail}</p> </Fade>}
          </div>
          <button ref={this.refButton} className="App-button" type="submit" onClick={(e) => this.submitFunction(e)} >Join</button>
        </div>

        <Dialog
          open={openSuccess}
          TransitionComponent={this.Transition}
          keepMounted
          onClose={() => this.handleClose()}
        // aria-describedby="alert-dialog-slide-description"
        // className='App-dialog'
        >
          <div className="App-dialog">
            <Lottie
              options={this.defaultOptions}
              height={100}
              width={100}
              // onAnimationFinish = {() => this.handleClose()}
              onAnimationFinish={this.onAnimationFinish}
            />
            {/* <h className='App-dialog-title'>{"Sent!"}</h> */}
            <p className='App-dialog-content'>
              Thank you for joining our Early Access waitlist. <br></br>We'll be in touch soon, stay tuned. :)
            </p >

            <button type="submit" className="App-button-success" onClick={() => this.handleClose()}>Close</button>

          </div>
        </Dialog>
      </div>
    )
  };

};


export default JoinRequest;



